import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Stack,
  Divider,
  Alert,
  Grid2,
} from "@mui/material";

export default function NewUserDialog({ open, onClose }) {
  const roles = ["Admin", "User"];
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    Name: "",
    Email: "",
    Password: "",
    ConfirmPassword: "",
    Phone: "",
    Role: "User", // Default role
    Status: true, // Default status is active
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    setError("");
    setLoading(true);

    // Validation: Check if Password and Confirm Password match
    if (userData.Password !== userData.ConfirmPassword) {
      setError("Passwords do not match!");
      setLoading(false);
      return;
    }

    // Simple validation
    if (
      !userData.Name ||
      !userData.Email ||
      !userData.Password ||
      !userData.Role
    ) {
      setError("Please fill all required fields");
      setLoading(false);
      return;
    }

    fetch(`/api/auth`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Name: userData.Name,
        Email: userData.Email,
        Password: userData.Password,
        Phone: userData.Phone,
        Role: userData.Role,
        Status: userData.Status,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((data) => {
            throw new Error(data.message || "An error occurred");
          });
        }
        return res.json();
      })
      .then(() => {
        setLoading(false);
        resetForm();
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message || "Failed to create user");
      });
  };

  const resetForm = () => {
    setUserData({
      Name: "",
      Email: "",
      Password: "",
      ConfirmPassword: "",
      Phone: "",
      Role: "User",
      Status: true,
    });
    setError("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Create New User</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            label="Name"
            name="Name"
            value={userData.Name}
            onChange={handleChange}
            fullWidth
          />
          <Grid2 container spacing={2}>
            <Grid2 size={{ md: 6, xs: 12 }}>
              <TextField
                label="Phone"
                name="Phone"
                value={userData.Phone}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
            <Grid2 size={{ md: 6, xs: 12 }}>
              <FormControl fullWidth>
                <InputLabel>Role</InputLabel>
                <Select
                  name="Role"
                  value={userData.Role}
                  onChange={handleChange}
                  fullWidth
                >
                  {roles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid2>
          </Grid2>
          <TextField
            label="Email"
            name="Email"
            value={userData.Email}
            onChange={handleChange}
            fullWidth
          />
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                label="Password"
                name="Password"
                type="password"
                value={userData.Password}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                label="Confirm Password"
                name="ConfirmPassword"
                type="password"
                value={userData.ConfirmPassword}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
          </Grid2>
          {error && <Alert severity="error">{error}</Alert>}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={resetForm} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
