import React from "react";
import Box from "@mui/material/Box";
import GIS from "../components/GIS/GIS";
import Settings from "../components/Settings/Settings";
import Markets from "../components/Markets/Markets";
import { Route, Routes } from "react-router-dom";
import Navbar from "../components/Util/Navbar";
import ElectionsHome from "../components/Elections/ElectionsHome";
import Stats from "../components/Stats/WPStats";
import UsersHome from "../components/Users/UsersHome";
import ProductsHome from "../components/Products/ProductHome";
import SingleElection from "../components/Elections/SingleElection";
import ClerkHome from "../components/Clerk/ClerkHome";

export default function Home() {
  const [user, setUser] = React.useState(null);

  return (
    <Box sx={{ display: "flex" }}>
      <Navbar setUser={setUser} />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Routes>
          <Route path="/" element={<Stats />} />
          <Route path="/clerks" element={<ClerkHome user={user} />} />
          <Route path="/products" element={<ProductsHome />} />
          <Route path="/elections" element={<ElectionsHome />} />
          <Route path="/elections/*" element={<SingleElection />} />
          <Route path="/markets" element={<Markets />} />
          <Route path="/map" element={<GIS />} />
          <Route path="/users" element={<UsersHome />} />
          <Route path="/settings" element={<Settings />} />
        </Routes>
      </Box>
    </Box>
  );
}
