import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  Divider,
  Grid2,
  Alert,
} from "@mui/material";
import countyData from "../../assets/Files/county_data.json"; // Import the county data JSON file

export default function NewMarketDialog({ open, onClose, onSubmit }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [subcounties, setSubcounties] = useState([]);
  const [wards, setWards] = useState([]);
  const [marketData, setMarketData] = useState({
    Name: "",
    Type: "",
    SubCounty: "",
    Ward: "",
    Latitude: "",
    Longitude: "",
  });

  // Load sub-counties from the county data JSON based on the selected county
  useEffect(() => {
    // Extract all subcounties when the dialog opens
    const allSubcounties = Object.keys(countyData).reduce(
      (acc, county) => acc.concat(Object.keys(countyData[county])),
      []
    );
    setSubcounties(allSubcounties);
  }, []);

  // Update the wards dropdown when a subcounty is selected
  useEffect(() => {
    if (marketData.SubCounty) {
      const countyName = Object.keys(countyData).find((county) =>
        Object.keys(countyData[county]).includes(marketData.SubCounty)
      );
      if (countyName) {
        const wardsList = countyData[countyName][marketData.SubCounty];
        setWards(wardsList || []);
      }
    }
  }, [marketData.SubCounty]);

  const handleChange = (e) => {
    setMarketData({
      ...marketData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    setError("");
    setLoading(true);

    // Simple validation
    if (
      !marketData.Name ||
      !marketData.Type ||
      !marketData.SubCounty ||
      !marketData.Ward
    ) {
      setError("Please fill all required fields");
      setLoading(false);
      return;
    }

    fetch(`/api/markets`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json", // Ensure Content-Type is set to JSON
      },
      body: JSON.stringify(marketData),
    })
      .then((res) => {
        // Check if the response is not OK (status code >= 400)
        if (!res.ok) {
          return res.json().then((data) => {
            throw new Error(data.message || "An error occurred"); // Throw an error to be caught later
          });
        }
        return res.json(); // Return the successful response
      })
      .then((data) => {
        setLoading(false);
        setError(data.message); // Clear any existing error
        setTimeout(() => {
          setError("");
          setMarketData({
            Name: "",
            Type: "",
            SubCounty: "",
            Ward: "",
            Latitude: "",
            Longitude: "",
          });
          onClose(); // Close the dialog after success
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message || "Failed to create market"); // Set the error message from the API or fallback
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>New Market</DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          label="Name"
          name="Name"
          value={marketData.Name}
          onChange={handleChange}
          fullWidth
          margin="dense"
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>Type</InputLabel>
          <Select name="Type" value={marketData.Type} onChange={handleChange}>
            <MenuItem value="Market Centres">Market Centres</MenuItem>
            <MenuItem value="Trading Centres">Trading Centres</MenuItem>
            <MenuItem value="Towns/Township">Towns/Township</MenuItem>
            <MenuItem value="Municipality">Municipality</MenuItem>
          </Select>
        </FormControl>

        <Grid2 spacing={2} container>
          <Grid2 size={{ md: 6, xs: 12 }}>
            <FormControl fullWidth margin="dense">
              <InputLabel>Sub County</InputLabel>
              <Select
                name="SubCounty"
                value={marketData.SubCounty}
                onChange={handleChange}
              >
                {subcounties.map((sub, index) => (
                  <MenuItem key={index} value={sub}>
                    {sub}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid2>
          <Grid2 size={{ md: 6, xs: 12 }}>
            <FormControl fullWidth margin="dense">
              <InputLabel>Ward</InputLabel>
              <Select
                name="Ward"
                value={marketData.Ward}
                onChange={handleChange}
              >
                {wards.map((ward, index) => (
                  <MenuItem key={index} value={ward}>
                    {ward}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid2>
        </Grid2>
        <Grid2 spacing={2} container>
          <Grid2 size={{ md: 6, xs: 12 }}>
            <TextField
              label="Latitude"
              name="Latitude"
              value={marketData.Latitude}
              onChange={handleChange}
              fullWidth
              margin="dense"
            />
          </Grid2>
          <Grid2 size={{ md: 6, xs: 12 }}>
            <TextField
              label="Longitude"
              name="Longitude"
              value={marketData.Longitude}
              onChange={handleChange}
              fullWidth
              margin="dense"
            />
          </Grid2>
        </Grid2>

        {error && <Alert color="warning">{error}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
