import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Box,
  Stack,
  Divider,
  Alert,
  Avatar,
  Grid2,
} from "@mui/material";

export default function NewCandidateDialog({ open, onClose }) {
  const [loading, setLoading] = useState(false);
  const [markets, setMarkets] = useState(null);
  const [elections, setElections] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [candidateData, setCandidateData] = useState({
    Name: "",
    Position: "",
    Gender: "",
    NationalID: "",
    MarketID: "",
    ElectionID: "",
    Category: "",
    Photo: "",
    Status: true,
  });
  const categories = [
    "Mali kwa Mali",
    "New Clothes",
    "Mtumba",
    "Cereals",
    "Vegetables",
    "Fruits",
    "Wholesale",
    "Regular Shops",
    "Bar & Restaurant",
    "Hotel",
    "Services",
    "Other",
  ];
  const [searchMarket, setSearchMarket] = useState("");
  const [searchElection, setSearchElection] = useState("");
  const [error, setError] = useState("");
  const [selectedMarket, setSelectedMarket] = useState("");
  const [selectedElection, setSelectedElection] = useState("");

  // Fetch markets based on the search query
  useEffect(() => {
    if (searchMarket !== "") {
      fetch(`/api/elections?market=${searchMarket}`)
        .then((res) => {
          if (res.ok) return res.json();
          else {
            res.json().then((data) => {
              throw Error(data.message ?? "Could not fetch!");
            });
          }
        })
        .then((data) => {
          if (data.data.length > 0) {
            setSelectedMarket(data.data[0].Market.Name);
            setMarkets(data.data[0].MarketID);
            setCandidateData({
              ...candidateData,
              MarketID: data.data[0].MarketID,
              ElectionID: data.data[0].ID,
              Market: data.data[0].Market.Name,
            });
          } else {
            setMarkets(null);
            setError("No market found!");
          }
        })
        .catch((err) => {
          setMarkets(null);
          setError(err.message ?? "Oops! something went wrong!");
        });
    } else {
      setError("");
      setMarkets(null);
      setSelectedMarket("");
      setCandidateData({
        ...candidateData,
        MarketID: "",
      });
    }
  }, [searchMarket]);

  // Fetch elections based on the search query
  useEffect(() => {
    if (searchElection !== "") {
      fetch(`/api/elections?market=${searchElection}`)
        .then((res) => {
          if (res.ok) return res.json();
          else {
            res.json().then((data) => {
              throw Error(data.message ?? "Could not fetch!");
            });
          }
        })
        .then((data) => {
          if (data.data.length > 0) {
            setSelectedElection(data.data[0].Type);
            setElections(data.data[0].ElectionID);
            setCandidateData({
              ...candidateData,
              ElectionID: data.data[0].ElectionID,
            });
          } else {
            setElections(null);
            setError("No election found!");
          }
        })
        .catch((err) => {
          setElections(null);
          setError(err.message ?? "Oops! something went wrong!");
        });
    } else {
      setError("");
      setElections(null);
      setSelectedElection("");
      setCandidateData({
        ...candidateData,
        ElectionID: "",
      });
    }
  }, [searchElection]);

  const handleChange = (e) => {
    if (e.target.name == "Market") {
      setSearchMarket(e.target.value);
    }
    setCandidateData({
      ...candidateData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPhotoPreview(reader.result);
      setCandidateData({
        ...candidateData,
        Photo: reader.result,
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = () => {
    setError("");
    setLoading(true);

    // Simple validation
    if (
      !candidateData.Name ||
      !candidateData.Position ||
      !candidateData.NationalID ||
      !candidateData.MarketID ||
      !candidateData.ElectionID ||
      !candidateData.Photo ||
      !candidateData.Gender
    ) {
      setError("Please fill all required fields");
      setLoading(false);
      return;
    }

    fetch(`/api/candidates`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(candidateData),
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((data) => {
            throw new Error(data.message || "An error occurred");
          });
        }
        return res.json();
      })
      .then((data) => {
        setLoading(false);
        setTimeout(() => {
          resetForm();
          onClose();
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message || "Failed to create candidate");
      });
  };

  const resetForm = () => {
    setCandidateData({
      Name: "",
      Position: "",
      Gender: "",
      NationalID: "",
      MarketID: "",
      ElectionID: "",
      Photo: "",
      Status: true,
    });
    setPhotoPreview(null);
    setSearchMarket("");
    setSearchElection("");
    setSelectedMarket("");
    setSelectedElection("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Create New Candidate</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={2}>
          <Box display="flex" justifyContent="center" mt={2}>
            <Avatar
              src={photoPreview}
              alt="Candidate Photo"
              sx={{ width: 100, height: 100 }}
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <input type="file" accept="image/*" onChange={handlePhotoUpload} />
          </Box>
          <Alert color={searchMarket == "" ? "warning" : "success"}>
            {searchMarket == ""
              ? "Search market below"
              : selectedMarket != ""
              ? selectedMarket
              : error != ""
              ? error
              : "..."}
          </Alert>
          <Grid2 container spacing={2}>
            <Grid2 size={{ md: 6, xs: 12 }}>
              <TextField
                label="Search Market"
                name="Market"
                value={searchMarket}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
            <Grid2 size={{ md: 6, xs: 12 }}>
              <FormControl fullWidth>
                <InputLabel>Category</InputLabel>
                <Select
                  name="Category"
                  value={candidateData.Category}
                  onChange={handleChange}
                  fullWidth
                >
                  {categories.map((item, i) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid2>
          </Grid2>
          <Grid2 container spacing={2}>
            <Grid2 size={{ md: 4, xs: 12 }}>
              <TextField
                label="Name"
                name="Name"
                value={candidateData.Name}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
            <Grid2 size={{ md: 4, xs: 12 }}>
              <FormControl fullWidth>
                <InputLabel>Position</InputLabel>
                <Select
                  name="Position"
                  value={candidateData.Position}
                  onChange={handleChange}
                  fullWidth
                >
                  <MenuItem value="Chair">Chair</MenuItem>
                  <MenuItem value="Treasurer">Treasurer</MenuItem>
                  <MenuItem value="Secretary">Secretary</MenuItem>
                </Select>
              </FormControl>
            </Grid2>
            <Grid2 size={{ md: 4, xs: 12 }}>
              <FormControl fullWidth>
                <InputLabel>Gender</InputLabel>
                <Select
                  name="Gender"
                  value={candidateData.Gender}
                  onChange={handleChange}
                  fullWidth
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
              </FormControl>
            </Grid2>
          </Grid2>

          <TextField
            label="National ID"
            name="NationalID"
            value={candidateData.NationalID}
            onChange={handleChange}
            fullWidth
          />
          {error ? (
            <Alert color="warning">{error}</Alert>
          ) : (
            <Box sx={{ height: "44px" }}></Box>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={resetForm} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
