import React, { useState } from "react";
import { ButtonGroup, Button, Box, Typography, Card } from "@mui/material";
import ProductDashboard from "./ProductDashboard";
import Products from "./Product";

export default function ProductsHome(props) {
  const [selected, setSelected] = useState("Dashboard");

  const handleNavigation = (path) => {
    setSelected(path);
  };

  return (
    <Box mt={9}>
      <ButtonGroup aria-label="outlined primary button group">
        <Button
          variant={selected == "Dashboard" ? "contained" : "outlined"}
          sx={{ textTransform: "capitalize" }}
          onClick={() => handleNavigation("Dashboard")}
        >
          Dashboard
        </Button>
        <Button
          variant={selected == "Products" ? "contained" : "outlined"}
          sx={{ textTransform: "capitalize" }}
          onClick={() => handleNavigation("Products")}
        >
          Products
        </Button>
      </ButtonGroup>
      <Box
        sx={{
          padding: "16px",
          borderRadius: "16px",
          boxShadow: "0px 4px 20px #60606030",
        }}
        component={Card}
        mt={2}
      >
        {selected == "Dashboard" && <ProductDashboard />}
        {selected == "Products" && <Products />}
      </Box>
    </Box>
  );
}
