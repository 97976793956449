import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Alert,
  Card,
} from "@mui/material";
import logo from "../assets/imgs/icon.png";
import k1 from "../assets/imgs/k1.jpg";
import k2 from "../assets/imgs/k2.jpg";
import k3 from "../assets/imgs/k3.jpg";
import k4 from "../assets/imgs/k4.jpg";

const images = [k1, k2, k3, k4];

export default function Login() {
  const rfEmail = useRef();
  const rsEmail = useRef();
  const rfPassword = useRef();
  const [isErr, setIsErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [body, updateBody] = useState({
    Email: null,
  });
  const intervalRef = useRef(null);
  const [token, setToken] = useState(null);
  const [active, setActive] = useState("login");
  const [selected, setSelected] = useState("Polling Clerk");
  const [currentImage, setCurrentImage] = useState(0);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    if (intervalRef.current) clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        setFade(false);
      }, 1000);
    }, 15000);

    return () => clearInterval(intervalRef.current);
  }, []);

  const login = () => {
    let d = body;
    d.Email = rfEmail.current.value.toLowerCase().trim();
    d.Password = rfPassword.current.value;
    updateBody(d);
    setIsErr("");

    if (!validateEmail(body.Email))
      return setIsErr("Please Enter a Valid Email Address!");
    if (!validatePassword(body.Password))
      return setIsErr("Password must be at least 6 characters!");

    if (validateEmail(body.Email) && validatePassword(body.Password)) {
      setLoading(true);
      const endpoint =
        selected === "Admin" ? "/api/auth/login" : "/api/clerks/login";
      fetch(endpoint, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            return res.json().then((err) => {
              // Convert error message to a readable format if the response is not ok
              throw new Error(err.message || "Login Failed");
            });
          }
        })
        .then((data) => {
          setIsErr(data.message); // Clear any existing errors
          setToken(data.token); // Set token from API
          setLoading(false);

          setTimeout(() => {
            localStorage.setItem("fmgdfhgfhtkn", data.token);
            window.location.href = selected === "Admin" ?  "/" : "/clerks";
          }, 500);
        })
        .catch((error) => {
          setLoading(false);
          setIsErr(error.message || "Login failed");
        });
    }
  };

  const reset = () => {
    let d = body;
    d.Email = rsEmail.current.value.toLowerCase().trim();
    updateBody(d);
    setIsErr("");

    if (!validateEmail(body.Email))
      return setIsErr("Please Enter a Valid Email Address!");

    if (validateEmail(body.Email)) {
      setLoading(true);
      fetch("/api/auth/forgot", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error("Login Failed");
          }
        })
        .then((data) => {
          if (data.success) {
            localStorage.setItem("fmgdfhgfhtkn", data.token);
            localStorage.removeItem("path");
            setIsErr(data.success);
            setLoading(false);
            window.location.href = "/";
          } else {
            setLoading(false);
            setIsErr(data.error);
          }
        })
        .catch(() => {
          setLoading(false);
          setIsErr("Login failed");
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]/.,;:\s@"]+(\.[^<>()[\]/.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        backgroundColor: "#c9d6ff",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          backgroundColor: "#000",
        }}
      >
        <Box
          component="img"
          src={images[currentImage]}
          alt={`Slide ${currentImage}`}
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            opacity: fade ? 0 : 1,
            transition: "opacity 1s ease-in-out",
          }}
        />
      </Box>

      <Card
        sx={{
          position: "absolute",
          top: 10,
          left: 10,
          bottom: 10,
          maxWidth: 500,
          width: "100%",
          padding: 4,
          borderRadius: 4,
          display: "flex",
          alignItems: "center",
          backgroundColor: "#23B0E2",
          color: "white",
        }}
      >
        {active === "login" ? (
          <Box
            component="form"
            onSubmit={(e) => e.preventDefault()}
            sx={{ textAlign: "center", height: "fit-content" }}
          >
            <Box
              component="img"
              src={logo}
              alt="Logo"
              sx={{ maxWidth: 100, margin: "0 auto" }}
            />
            <Typography variant="h4" sx={{ margin: "8px 0" }}>
              Sign In
            </Typography>
            <ToggleButtonGroup
              color="primary"
              value={selected}
              exclusive
              onChange={(e, newSelection) => setSelected(newSelection)}
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: 0,
                backgroundColor: "white",
                borderRadius: "5px",
              }}
            >
              <ToggleButton value="Polling Clerk" sx={{ fontWeight: 400 }}>
                Polling Clerk
              </ToggleButton>
              <ToggleButton value="Admin" sx={{ fontWeight: 400 }}>
                Admin
              </ToggleButton>
            </ToggleButtonGroup>

            <TextField
              inputRef={rfEmail}
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <TextField
              inputRef={rfPassword}
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <Typography variant="body2">
              Forgot password?{" "}
              <Button color="secondary" onClick={() => setActive("reset")}>
                Click Here
              </Button>
            </Typography>

            <Button
              onClick={login}
              variant="contained"
              color="primary"
              fullWidth
              sx={{ marginTop: 2 }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Sign In"
              )}
            </Button>
            {isErr != "" && (
              <Alert color="warning" sx={{ my: 2 }}>
                {isErr}
              </Alert>
            )}
          </Box>
        ) : (
          <Box
            component="form"
            onSubmit={(e) => e.preventDefault()}
            sx={{ textAlign: "center", height: "fit-content", width: "100%" }}
          >
            <Typography variant="h4" sx={{ margin: "10px 0" }}>
              Reset Password
            </Typography>

            <TextField
              inputRef={rsEmail}
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <Button
              onClick={reset}
              variant="contained"
              color="primary"
              fullWidth
              sx={{ marginTop: 2 }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
            <Button
              onClick={() => setActive("login")}
              color="secondary"
              sx={{ marginTop: 2 }}
            >
              Sign In
            </Button>
            {isErr != "" && (
              <Alert color="warning" sx={{ my: 2 }}>
                {isErr}
              </Alert>
            )}
          </Box>
        )}
      </Card>
    </Box>
  );
}
