import "../../Styles/elections.scss";
import CustomBarChart from "../Stats/CustomBarChart";
import GaugeChart from "react-gauge-chart";
import { FiArrowLeft } from "react-icons/fi";
import CandidateBox from "./CandidateBox";
import { useEffect, useState } from "react";
import Clerks from "./Clerks";
import {
  Box,
  Typography,
  Button,
  Grid2,
  Paper,
  Divider,
  CircularProgress,
  CardContent,
  Card,
  Chip,
} from "@mui/material";
import { BarChart } from "recharts";

export default function SingleElection(props) {
  const market = window.location.pathname.split("/")[2];
  const [stats, setStats] = useState(null);
  const [status, setStatus] = useState(false);

  console.log(market);

  const fetchStats = (v) => {
    fetch(`/api/elections/adminelectats?marketId=${v}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("Failed to fetch data");
      })
      .then((data) => {
        setStats(data);
        setStatus(data.election.Status);
        console.log(data);
      })
      .catch((e) => {
        console.error("Error fetching data:", e);
      });
  };

  useEffect(() => {
    if (market) {
      fetchStats(market);

      const intervalId = setInterval(() => {
        fetchStats(market);
      }, 300000); // 5 minutes in milliseconds

      return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }
  }, [market]);

  function startStopElection() {
    if (stats) {
      fetch(`/api/elections/${stats.election?.ID}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ Status: !status }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("");
        })
        .then((data) => {
          console.log(data);
          if (data.success != null) setStatus(!status);
        })
        .catch((err) => {});
    }
  }

  return (
    <Box sx={{ marginTop: 8 }}>
      <Card
        elevation={3}
        sx={{ borderRadius: "12px", p: 3, boxShadow: "0px 8px 30px #60606040" }}
      >
        <Grid2 container spacing={3} alignItems="center">
          <Grid2 size={{ xs: 12 }}>
            <Typography variant="h6">Election Details</Typography>
          </Grid2>
          <StatCard title="Candidates" value={stats?.candidateCount ?? 0} />
          <StatCard title="Voters" value={stats?.voterCount ?? 0} />
          <StatCard title="Clerks" value={stats?.clerkCount ?? 0} />
          <StatCard title="Turnout" value={stats?.tallyCountByMarket ?? 0} />

          <Grid2>
            <Divider />
          </Grid2>

          <Grid2 size={{ xs: 12 }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color={status ? "error" : "success"}
                onClick={startStopElection}
              >
                {status ? "Stop Election" : "Start Election"}
              </Button>
            </Box>
          </Grid2>
        </Grid2>
        {/* <Grid2 container spacing={3}>
          <Grid2 size={{ xs: 12, md: 8 }}>
            <Typography variant="h6">Voting Category</Typography>
            <BarChart
              xAxis={[{ dataKey: "Category", label: "Category" }]} // X-axis label
              series={[{ dataKey: "count", label: "Tally Count" }]} // Y-axis label
              height={400}
              data={stats ? stats.talliesPerCategory : []} // Pass the fetched data here
            />
          </Grid2>

          <Grid2 size={{ xs: 12, md: 4 }}>
            <Typography variant="h6">Voter Turnout Gauge</Typography>
            <GaugeChart
              className="gg"
              id="gauge-chart2"
              nrOfLevels={3}
              colors={["#FF0000", "#FFA500", "#008000"]}
              arcWidth={1}
              percent={stats ? stats.tallyCountByMarket / stats.voterCount : 0}
              textColor="black"
            />
          </Grid2>
        </Grid2> */}
        <Box item xs={12}>
          {stats && stats.candidatesWithTallyCount.length > 0 ? (
            stats.candidatesWithTallyCount.map((item, i) => (
              <Box key={i} sx={{ marginTop: 4 }}>
                <Typography variant="title" gutterBottom></Typography>
                <Divider sx={{ marginY: 2 }} />
                <Box container spacing={2} component={Paper} p={2}>
                  {item.positions.map((ps, index) => {
                    let x = ps.candidates.reduce(
                      (sum, e) => sum + parseInt(e.tallyCount || 0, 10),
                      0
                    );

                    return (
                      <Box key={index}>
                        <Typography variant="body2" gutterBottom>
                          {ps.Position}
                        </Typography>
                        <Grid2 container spacing={3}>
                          {ps.candidates.map((candidate, index) => (
                            <CandidateBox
                              key={index}
                              total={x}
                              size={ps.candidates.length}
                              index={index}
                              candidate={candidate}
                            />
                          ))}
                        </Grid2>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            ))
          ) : (
            <Box sx={{ textAlign: "center", marginTop: 3 }}>
              <CircularProgress />
              <Typography variant="body1" sx={{ marginTop: 2 }}>
                Loading Election Data...
              </Typography>
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
}

const StatCard = ({ title, value }) => (
  <Grid2 mt={2} size={{ xs: 12, md: 3 }}>
    <Card
      sx={{ borderRadius: "12px", p: 1, boxShadow: "0px 8px 30px #60606040" }}
    >
      <CardContent>
        <Typography variant="subtitle1" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h4">{value}</Typography>
      </CardContent>
    </Card>
  </Grid2>
);
