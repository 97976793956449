import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { BarChart } from "@mui/x-charts/BarChart";
import { Ballot } from "@mui/icons-material";

function ElectionDashboard() {
  const [data, setData] = useState();
  const [height, setHeight] = useState(300);
  const [priceChart, setPriceChart] = useState(null);
  const [type, setType] = useState("count");

  useEffect(() => {
    const fetchData = async () => {
      try {
        fetch("/api/elections/statistics", {
          credentials: "include",
        })
          .then((res) => {
            if (res.ok) return res.json();
            else throw new Error("");
          })
          .then((data) => {
            setData(data);
          })
          .catch((e) => {});
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        fetch(`/api/elections/elections/stats?type=${type}`, {
          credentials: "include",
        })
          .then((res) => {
            if (res.ok) return res.json();
            else throw new Error("");
          })
          .then((data) => {
            console.log(data);

            setPriceChart(data);
            setHeight(data.series.length * 150);
          })
          .catch((e) => {});
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [type]);

  return (
    <Box marginTop={1}>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, md: 3, sm: 6 }}>
          <TopItem title="Voters" value={data?.totalVoters ?? 0} />
        </Grid>

        <Grid size={{ xs: 12, md: 3, sm: 6 }}>
          <TopItem title="Candidates" value={data?.totalCandidates ?? 0} />
        </Grid>
        <Grid size={{ xs: 12, md: 3, sm: 6 }}>
          <TopItem title="Markets" value={data?.totalMarkets ?? 0} />
        </Grid>
        <Grid size={{ xs: 12, md: 3, sm: 6 }}>
          <TopItem title="Elections" value={data?.totalElections ?? 0} />
        </Grid>
        <Grid size={{ md: 6, xs: 12 }}>
          <Card
            style={{
              borderRadius: "16px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
            }}
          >
            <Typography p={2}>Voters by SubCounty</Typography>
            <BarChart
              title="Voters by SubCounty"
              xAxis={[
                {
                  scaleType: "band",
                  data: data
                    ? data?.chartData.votersBySubCounty.map((item) => item.name)
                    : ["No data"],
                },
              ]}
              series={[
                {
                  data: data
                    ? data?.chartData.votersBySubCounty.map(
                        (item) => item.value
                      )
                    : [0],
                },
              ]}
              height={300}
            />
          </Card>
        </Grid>
        <Grid size={{ md: 6, xs: 12 }}>
          <Card
            style={{
              borderRadius: "16px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
            }}
          >
            <Typography p={2}>Voters by Ward</Typography>
            <BarChart
              title="Voters by Ward"
              xAxis={[
                {
                  scaleType: "band",
                  data: data
                    ? data?.chartData.votersByWard.map((item) => item.name)
                    : ["No data"],
                },
              ]}
              series={[
                {
                  data: data
                    ? data?.chartData.votersByWard.map((item) => item.value)
                    : [0],
                },
              ]}
              height={300}
            />
          </Card>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Card
            style={{
              borderRadius: "16px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
            }}
          >
            <Typography p={2}>Voters by Market</Typography>
            <BarChart
              title="Voters by Ward"
              xAxis={[
                {
                  scaleType: "band",
                  data: data
                    ? data?.chartData.candidatesByMarket.map(
                        (item) => item.name
                      )
                    : ["No data"],
                },
              ]}
              series={[
                {
                  data: data
                    ? data?.chartData.candidatesByMarket.map(
                        (item) => item.value
                      )
                    : [0],
                },
              ]}
              height={400}
            />
          </Card>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Card
            style={{
              borderRadius: "16px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
            }}
          >
            <Box p={2} sx={{ display: "flex" }}>
              <Typography flexGrow={1}>Products by Name</Typography>
              <Select
                label="Filter"
                size="small"
                value="count"
                onChange={(e) => {
                  setType(e.target.value);
                }}
                sx={{ minWidth: "150px" }}
              >
                <MenuItem value="count">Total Products</MenuItem>
                <MenuItem value="average">Average Price</MenuItem>
                <MenuItem value="max">Max Price</MenuItem>
                <MenuItem value="min">Min Price</MenuItem>
              </Select>
            </Box>
            <BarChart
              title="Products by Market and Name"
              yAxis={[
                {
                  scaleType: "band",
                  data: priceChart?.chartCategories || ["No data"],
                },
              ]}
              xAxis={[
                {
                  scaleType: "linear",
                  label: "Products",
                },
              ]}
              series={
                priceChart?.series?.map((serie) => ({
                  name: serie.label,
                  data: serie.data.map((value) => parseInt(value, 10)),
                })) || [{ name: "No data", data: [0] }]
              }
              layout="horizontal"
              height={height}
              // Add padding and margin here
              margin={{
                top: 20,
                right: 30,
                left: 100, // Increase the left margin to avoid cropping labels
                bottom: 20,
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ElectionDashboard;

const TopItem = ({ title, value }) => {
  return (
    <Card
      style={{
        borderRadius: "16px",
        boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
      }}
    >
      <CardContent sx={{ position: "relative" }}>
        <Stack spacing={3}>
          <Stack
            direction="row"
            sx={{ justifyContent: "space-between" }}
            spacing={3}
          >
            <Stack spacing={1}>
              <Typography color="text.secondary" variant="overline">
                {title}
              </Typography>
              <Typography variant="h4">{value}</Typography>
            </Stack>
            <Avatar
              sx={{
                backgroundColor: "#fa541c",
                height: "32px",
                width: "32px",
                position: "absolute",
                bottom: 10,
                right: 10,
              }}
            >
              <Ballot fontSize="16px" />
            </Avatar>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
