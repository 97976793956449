// hooks/useLoadingOnNavigate.js
import { useEffect, useState } from "react";

const useLoadingOnNavigate = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = () => setLoading(true);

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return loading;
};

export default useLoadingOnNavigate;
