import "../../Styles/clerks.scss";
import CustomBarChart from "../Stats/CustomBarChart";
import GaugeChart from "react-gauge-chart";
import { FiArrowLeft } from "react-icons/fi";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  Divider,
  Grid2,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

export default function ClerkHome(props) {
  const [market, setMarket] = useState(null);
  const [id, setID] = useState(null);
  const [ele, setEle] = useState(null);
  const [data, setData] = useState(null);
  const [stats, setStats] = useState(null);
  const [currentVoter, setCurrentVoter] = useState(null);
  const [voterDetails, setVoterDetails] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    if (props.user) {
      checkVoting();
      setMarket(props.user.Market);
      setEle(props.user.Market.Elections[0]);

      if (props.user.Market.Elections[0].Status === false) {
        setIsPopupOpen(true);
      }
    }
  }, [props.user]);

  const handleRefresh = () => {
    window.location.reload();
  };

  const fetchStats = () => {
    if (ele) {
      fetch(
        `/api/elections/myclerkstats?clerkId=${props.user.UserID}&marketId=${market.MarketID}`
      )
        .then((res) =>
          res.ok ? res.json() : Promise.reject("Failed to fetch data")
        )
        .then((data) => setStats(data))
        .catch((e) => console.error("Error fetching data:", e));
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (ele && ele.Status && currentVoter) {
        if (props.user) {
          checkVoting();
        }
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [id, market, ele, props.user]);

  useEffect(() => {
    fetchStats();

    const intervalId = setInterval(() => {
      if (ele && ele.Status) {
        fetchStats();
      }
    }, 240000);

    return () => clearInterval(intervalId);
  }, [ele]);

  function initiateVoting(voter) {
    fetch(`/api/tally`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        VoterID: voter.UserID,
        ElectionID: props.user.Market.Elections[0].ID,
        MarketID: props.user.Market.MarketID,
        ClerkID: props.user.UserID,
      }),
    })
      .then((response) => (response.ok ? response.json() : Promise.reject()))
      .then((data) => {
        fetchStats();

        setCurrentVoter(data);
        setVoterDetails(voter);
        setData(null);
      })
      .catch(() => {});
  }

  function revokeVoting() {
    fetch(`/api/tally/${currentVoter.ID}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => (response.ok ? response.json() : Promise.reject()))
      .then((data) => {
        fetchStats();
        setCurrentVoter(null);
        setVoterDetails(null);
        setData(null);
      })
      .catch(() => {
        fetchStats();
        setCurrentVoter(null);
        setVoterDetails(null);
        setData(null);
      });
  }

  function quickSearch(value) {
    if (market == null) return;
    setData(null);
    fetch(`/api/voters?nationalid=${value}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => (res.ok ? res.json() : null))
      .then((data) => {
        setData(data);
      })
      .catch(() => {});
  }

  function checkVoting() {
    fetch(`/api/tally/byclerk?clerkId=${props.user.UserID}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => (res.ok ? res.json() : null))
      .then((data) => {
        if (data && data.length > 0) {
          setCurrentVoter(data[0]);
          setVoterDetails(data[0].Voter);
        }
      })
      .catch(() => {});
  }

  return (
    <Box mt={8}>
      <Box className="singleelection">
        <Box
          className="welcome"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">{market?.Name} Elections</Typography>
        </Box>

        <Box className="top" mb={2}>
          <Grid2 container spacing={2}>
            <StatCard title="Candidates" value={stats?.candidateCount} />
            <StatCard title="Registered Voters" value={stats?.voterCount} />
            <StatCard title="My Tally" value={stats?.tallyCountByClerk} />
            <StatCard title="Voter Turnout" value={stats?.tallyCountByMarket} />
          </Grid2>
        </Box>

        <Box className="selectvoter">
          <Grid2 container spacing={4}>
            <Grid2 size={{ xs: 12, md: 8 }}>
              <Card
                sx={{
                  borderRadius: "12px",
                  p: 2,
                  boxShadow: "0px 8px 30px #60606040",
                }}
              >
                <Box className="search" display="flex" alignItems="center">
                  <Typography flexGrow={1} variant="h6">
                    Verify Voters
                  </Typography>
                  <TextField
                    size="small"
                    variant="outlined"
                    placeholder="Search voter by ID"
                    onChange={(e) => {
                      if (ele && ele.Status) {
                        quickSearch(e.target.value);
                      }
                    }}
                  />
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box sx={{ minHeight: "40vh" }}>
                  {currentVoter == null &&
                    data?.data?.map((item, index) => (
                      <Voter
                        key={index}
                        item={item}
                        initiateVoting={initiateVoting}
                        setCurrentVoter={setCurrentVoter}
                        setVoterDetails={setVoterDetails}
                      />
                    ))}
                  {currentVoter && (
                    <Typography>
                      Voting in progress! Reset voter if needed.
                    </Typography>
                  )}
                </Box>
              </Card>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <Box
                sx={{
                  borderRadius: "12px",
                  p: 2,
                  boxShadow: "0px 8px 30px #60606040",
                }}
                component={Card}
                mb={2}
              >
                <Typography variant="subtitle1">Reset Voter</Typography>
                <Divider sx={{ my: 1 }} />
                <Button
                  sx={{ margin: "auto", display: "block" }}
                  variant="contained"
                  disabled={!currentVoter}
                  color={"primary"}
                  onClick={() => revokeVoting()}
                >
                  {currentVoter ? "Reset Voter" : "No Action"}
                </Button>
              </Box>

              <Box
                sx={{
                  borderRadius: "12px",
                  p: 2,
                  boxShadow: "0px 8px 30px #60606040",
                  minHeight: "32vh",
                }}
                component={Card}
              >
                <Typography variant="subtitle1">Current Voter</Typography>
                <Divider sx={{ my: 1 }} />
                {voterDetails ? (
                  <Box>
                    <Typography variant="h6">
                      {voterDetails.NationalID}
                    </Typography>
                    <Typography variant="body1">{voterDetails.Name}</Typography>
                    <Typography variant="body2">
                      {voterDetails.Category}
                    </Typography>
                  </Box>
                ) : (
                  <Typography>No Voter is voting currently!</Typography>
                )}
              </Box>
            </Grid2>
          </Grid2>
        </Box>
      </Box>
      <Dialog
        open={isPopupOpen}
        disableEscapeKeyDown
        disableBackdropClick
        aria-labelledby="election-status-dialog-title"
      >
        <DialogTitle id="election-status-dialog-title">
          Election Not Started
        </DialogTitle>
        <DialogContent>
          <Typography>
            The election has not started yet. Please refresh the page to check
            if the election is active.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              window.location.href = "/login";
            }}
          >
            Logout
          </Button>
          <Button variant="contained" color="primary" onClick={handleRefresh}>
            Refresh
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const StatCard = ({ title, value }) => (
  <Grid2 mt={2} size={{ xs: 12, md: 3 }}>
    <Card
      sx={{ borderRadius: "12px", p: 1, boxShadow: "0px 8px 30px #60606040" }}
    >
      <CardContent>
        <Typography variant="subtitle1" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h4">{value}</Typography>
      </CardContent>
    </Card>
  </Grid2>
);

const Voter = (props) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/tally?voter=${props.item.UserID}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => (res.ok ? res.json() : null))
      .then((data) => {
        setLoading(false);
        setData(data);
        if (data && data.data.length > 0) {
          if (!data.data[0].Status) {
            props.setCurrentVoter(data.data[0]);
            props.setVoterDetails(props.item);
          }
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [props.item]);

  return (
    <Card className="voter" sx={{ mb: 2 }}>
      <CardContent sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Chip
          sx={{ fontSize: "large", px: 2 }}
          label={props.item.NationalID}
        ></Chip>
        <Box>
          <Typography variant="body2">{props.item.Name}</Typography>
          <Typography variant="body2">{props.item.Phone}</Typography>
        </Box>
        <Box>
          <Typography variant="body2">{props.item.Market.SubCounty}</Typography>
          <Typography variant="body2">{props.item.Market.Ward}</Typography>
          <Typography variant="body2">{props.item.Market.Name}</Typography>
        </Box>
        <Typography flexGrow={1} variant="body2">
          {props.item.Category}
        </Typography>
        <Button
          variant="contained"
          color={"primary"}
          disabled={data != null && data.total != 0}
          onClick={() => {
            props.initiateVoting(props.item);
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : data == null ? (
            "..."
          ) : data.total == 0 ? (
            "Select to Vote"
          ) : data.data[0].Status ? (
            "Voted"
          ) : (
            "Voting In Progress"
          )}
        </Button>
      </CardContent>
    </Card>
  );
};
