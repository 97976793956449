import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  LinearProgress,
  Avatar,
  Grid2,
  Card,
} from "@mui/material";
import thumb from "../../assets/imgs/thumb.png";

export default function CandidateBox(props) {
  const [data, setData] = useState(0);

  useEffect(() => {
    if (props.candidate) {
      let d = parseInt(props.candidate.tallyCount);
      setData(((d / props.total) * 100).toFixed(0));
    }
  }, [props.candidate, props.total]);

  return (
    <Grid2 size={{ xs: 12, md: 12 / props.size }}>
      <Box
        sx={{
          mb: 2,
          padding: 2,
          border: "1px solid #ddd",
          borderRadius: "8px",
          position: "relative",
          boxShadow: "0px 8px 16px #60606040",
        }}
        component={Card}
      >
        <Typography variant="p" gutterBottom>
          {props.candidate.Name} - {data}%
        </Typography>

        <Box sx={{ position: "relative", mt: 2, width: "100%" }}>
          <LinearProgress
            variant="determinate"
            value={parseInt(data)} // MUI LinearProgress expects a number between 0 and 100
            sx={{
              height: 10,
              borderRadius: 5,
            }}
          />
        </Box>
      </Box>
    </Grid2>
  );
}
