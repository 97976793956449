export default function Button(props) {
  return (
    <button
      className="button"
      onClick={() => {
        props.handleClick();
      }}
    >
      {props.loading ? <div class="bloader"></div> : props.value}
    </button>
  );
}
