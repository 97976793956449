import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from "react";
// Define your email regex
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const Input = forwardRef((props, ref) => {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputIdRef = useRef(`input_${Math.random().toString(36).substr(2, 9)}`);
  const [isValidEmail, setIsValidEmail] = useState(true);

  useEffect(() => {
    if (props.type === "email" && inputValue != "") {
      setIsValidEmail(emailRegex.test(inputValue));
    }else {
      setIsValidEmail(true)
    }
  }, [inputValue, props.type]);

  useEffect(() => {
    if (props.value) {
      setInputValue(props.value);
    }
    if (props.isFocused) {
      setIsFocused(true);
    }
  }, [props.value]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(inputValue !== "");
  };

  const handleChange = (e) => {
    if (props.handleChange) {
      props.handleChange(e.target.value);
    }
    setInputValue(e.target.value);
  };

  useImperativeHandle(ref, () => ({
    focusInput: () => {
      ref.current.focus();
    },
    value: inputValue,
  }));

  return (
    <div className={`input-container ${isFocused ? "focused" : ""}`}>
      <label
        className={`label ${isFocused || inputValue !== "" ? "up" : ""}`}
        htmlFor={inputIdRef.current}
        style={{color: isValidEmail ? "" : ""}}
      >
        {isValidEmail ? props.label : "Invalid email"}
      </label>
      {props.type === "textarea" ? (
        <textarea
          ref={ref}
          id={inputIdRef.current}
          rows={5}
          value={inputValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      ) : (
        <input
          type={props.type}
          id={inputIdRef.current}
          ref={ref}
          value={inputValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      )}
    </div>
  );
});

export default Input;
