import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Box,
  Stack,
  Divider,
  Alert,
} from "@mui/material";

export default function NewElectionDialog({ open, onClose }) {
  const [loading, setLoading] = useState(false);
  const [markets, setMarkets] = useState(null);
  const [electionData, setElectionData] = useState({
    Type: "Normal",
    SubCounty: "",
    Ward: "",
    MarketName: "",
    Voting: false,
    MarketID: "",
    Date: new Date().toISOString().split("T")[0],
    Status: false,
  });
  const [searchMarket, setSearchMarket] = useState("");
  const [error, setError] = useState("");
  const [selectedMarket, setSelectedMarket] = useState("");

  // Fetch markets based on the search query
  useEffect(() => {
    if (searchMarket != "") {
      setError("");
      fetch(`/api/markets?market=${searchMarket}`)
        .then((res) => {
          if (res.ok) return res.json();
          else {
            res.json().then((data) => {
              throw Error(data.message ?? "Could not fetch!");
            });
          }
        })
        .then((data) => {
          if (data.data.length > 0) {
            setSelectedMarket(data.data[0].Name);
            setMarkets(data.data[0].MarketID);
            setElectionData({
              ...electionData,
              MarketID: data.data[0].MarketID,
              Market: data.data[0].Name,
              MarketName: data.data[0].Name,
              SubCounty: data.data[0].SubCounty,
              Ward: data.data[0].Ward,
            });
          } else {
            setMarkets(null);
            setError("No market found!");
          }
        })
        .catch((err) => {
          setMarkets(null);
          setError(err.message ?? "Oops! something went wrong!");
        });
    } else {
      setError("");
      setMarkets(null);
      setSelectedMarket("");
      setElectionData({
        ...electionData,
        MarketID: "",
        Market: "",
      });
    }
  }, [searchMarket]);

  const handleChange = (e) => {
    if (e.target.name == "Market") {
      setSearchMarket(e.target.value);
    }
    setElectionData({
      ...electionData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    setError("");
    setLoading(true);

    // Simple validation
    if (!electionData.Type || !electionData.MarketID || !electionData.Date) {
      setError("Please fill all required fields");
      setLoading(false);
      return;
    }

    fetch(`/api/elections`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json", // Ensure Content-Type is set to JSON
      },
      body: JSON.stringify(electionData),
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((data) => {
            throw new Error(data.message || "An error occurred");
          });
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);

        setLoading(false);
        setSelectedMarket(data.message);
        setTimeout(() => {
          setElectionData({
            Type: "",
            Market: "",
            MarketID: "",
            Date: new Date().toISOString().split("T")[0],
            Status: true,
          });
          setError("");
          setSelectedMarket("");
          setSearchMarket("");
          onClose();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        setSelectedMarket(err.message || "Failed to create election");
        setLoading(false);
        setError(err.message || "Failed to create election");
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Create New Election</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={2}>
          <Alert color={searchMarket == "" ? "warning" : "success"}>
            {searchMarket == ""
              ? "Search market below"
              : selectedMarket != ""
              ? selectedMarket
              : error != ""
              ? error
              : "..."}
          </Alert>

          <TextField
            sx={{ mt: 1 }}
            label="Search Market"
            name="Market"
            value={searchMarket}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            name="Date"
            type="date"
            label="Election Date"
            value={electionData.Date}
            onChange={handleChange}
            fullWidth
          />
          {error && (
            <Alert color={"warning"}>{error != "" ? error : "..."}</Alert>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            setElectionData({
              Type: "",
              Market: "",
              MarketID: "",
              Date: new Date().toISOString().split("T")[0],
              Status: true,
            });
            setError("");
            setSelectedMarket("");
            setSearchMarket("");
            onClose();
          }}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
