import React, { useState } from "react";
import { ButtonGroup, Button, Box, Typography, Card } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Assuming you're using react-router for navigation
import ElectionDashboard from "./ElectionDashboard";
import Elections from "./Elections";
import Voters from "../Voters/Voters";
import Candidates from "../Candidates/Candidates";

export default function ElectionsHome(props) {
  const [selected, setSelected] = useState("Dashboard");

  const handleNavigation = (path) => {
    setSelected(path);
  };

  return (
    <Box mt={9}>
      <ButtonGroup aria-label="outlined primary button group">
        <Button
          variant={selected == "Dashboard" ? "contained" : "outlined"}
          sx={{ textTransform: "capitalize" }}
          onClick={() => handleNavigation("Dashboard")}
        >
          Dashboard
        </Button>
        <Button
          variant={selected == "Elections" ? "contained" : "outlined"}
          sx={{ textTransform: "capitalize" }}
          onClick={() => handleNavigation("Elections")}
        >
          Elections
        </Button>
        <Button
          variant={selected == "Candidates" ? "contained" : "outlined"}
          sx={{ textTransform: "capitalize" }}
          onClick={() => handleNavigation("Candidates")}
        >
          Candidates
        </Button>
        <Button
          variant={selected == "Voters" ? "contained" : "outlined"}
          sx={{ textTransform: "capitalize" }}
          onClick={() => handleNavigation("Voters")}
        >
          Voters
        </Button>
      </ButtonGroup>
      <Box
        sx={{
          padding: "16px",
          borderRadius: "16px",
          boxShadow: "0px 4px 20px #60606030",
        }}
        component={Card}
        mt={2}
      >
        {selected == "Dashboard" && <ElectionDashboard />}
        {selected == "Elections" && <Elections />}
        {selected == "Candidates" && <Candidates />}
        {selected == "Voters" && <Voters />}
      </Box>
    </Box>
  );
}
