import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  Pagination,
  Chip,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import Loading from "../Util/Loading";
import NewMarketDialog from "./NewMarketDialog";

export default function Markets() {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/markets?offset=${offset * 10}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [offset, refresh]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      fetch(`/api/markets?market=${value}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => setData(data))
        .catch(() => setData(null));
    } else {
      setRefresh(!refresh);
    }
  };

  return (
    <Box
      component={Card}
      sx={{
        padding: "16px",
        borderRadius: "16px",
        boxShadow: "0px 4px 20px #60606030",
      }}
      mt={8}
    >
      <Box>
        <Box
          display="flex"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">Markets</Typography>
          <Box sx={{ flexGrow: 1 }}></Box>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Button onClick={() => setOpenDialog(true)} variant="contained">
            <AddIcon /> New Market
          </Button>
        </Box>

        {loading ? (
          <Loading />
        ) : (
          <TableContainer sx={{ borderRadius: "12px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">SN</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Sub County</TableCell>
                  <TableCell>Ward</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data?.length > 0 ? (
                  data.data.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell padding="checkbox">
                        <Chip label={offset * 10 + index + 1} />
                      </TableCell>
                      <TableCell>{item.Name}</TableCell>
                      <TableCell>{item.Type}</TableCell>
                      <TableCell>{item.SubCounty}</TableCell>
                      <TableCell>{item.Ward}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>No Markets Found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Box mt={1}>
          {data && (
            <Pagination
              count={Math.ceil(data.total / 10)}
              page={offset + 1}
              onChange={(e, value) => setOffset(value - 1)}
            />
          )}
        </Box>
      </Box>

      <NewMarketDialog open={openDialog} onClose={() => setOpenDialog(false)} />
    </Box>
  );
}
