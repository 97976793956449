import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid2,
  Box,
  Button,
  Stack,
} from "@mui/material";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { FiRefreshCcw } from "react-icons/fi";
import CustomBarChart from "../Stats/CustomBarChart";
import GaugeChart from "react-gauge-chart";
import thumb from "../../assets/imgs/thumb.png";
import { ArrowForwardIos } from "@mui/icons-material";

export default function Stats(props) {
  const [refresh, setRefresh] = useState(false);
  const [stats, setStats] = useState(null);

  const fetchStats = () => {
    fetch(`/api/elections/homestats`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("Failed to fetch data");
      })
      .then((data) => {
        setStats(data);
        console.log(data);
      })
      .catch((e) => {
        console.error("Error fetching data:", e);
      });
  };

  useEffect(() => {
    fetchStats();

    const intervalId = setInterval(() => {
      fetchStats();
    }, 300000); // 5 minutes in milliseconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  return (
    <Box sx={{ padding: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h4">Dashboard</Typography>
        <FiRefreshCcw
          onClick={() => {
            setRefresh(!refresh);
          }}
          className="refresh"
        />
      </Box>

      <Grid2 container spacing={3}>
        {/* Market Elections */}
        <Grid2 size={{ md: 6, xs: 12 }}>
          <Card sx={{ boxShadow: "0px 12px 30px #60606040", borderRadius: 6 }}>
            <CardContent component={Stack} spacing={3}>
              <Typography variant="h4" gutterBottom>
                Market Elections
              </Typography>
              <Typography variant="paragraph" color="textSecondary">
                - Manage voter registration <br />
              </Typography>
              <Typography variant="paragraph" color="textSecondary">
                - Track voter turnout <br />
              </Typography>
              <Typography variant="paragraph" color="textSecondary">
                - Monitor election status
              </Typography>
            </CardContent>
          </Card>
        </Grid2>

        {/* Market Data Collection */}
        <Grid2 size={{ md: 6, xs: 12 }}>
          <Card sx={{ boxShadow: "0px 12px 30px #60606040", borderRadius: 6 }}>
            <CardContent component={Stack} spacing={3}>
              <Typography variant="h4" gutterBottom>
                Market Data Collection
              </Typography>
              <Typography variant="paragraph" color="textSecondary">
                - Collect market product data <br />
              </Typography>
              <Typography variant="paragraph" color="textSecondary">
                - Analyze market trends <br />
              </Typography>
              <Typography variant="paragraph" color="textSecondary">
                - Generate reports
              </Typography>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>

      <Grid2 container spacing={2} sx={{ mt: 4 }}>
        {[
          { label: "Election Management", href: "/elections" },
          { label: "Market List", href: "/markets" },
          { label: "Market Products", href: "/products" },
          { label: "System Users", href: "/users" },
        ].map((link, index) => (
          <Grid2 item size={{ md: 3, xs: 6 }} key={index}>
            <Card
              sx={{
                boxShadow: "0px 12px 30px #60606040",
                borderRadius: 6,
                p: 3,
                height: "100%",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                window.location.href = link.href;
              }}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {link.label}
                </Typography>
                <ArrowForwardIos />
              </CardContent>
            </Card>
          </Grid2>
        ))}
      </Grid2>
    </Box>
  );
}
