import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Box,
  Stack,
  Divider,
  Alert,
  Avatar,
  Grid2,
} from "@mui/material";

export default function NewEnumeratorDialog({ open, onClose }) {
  const [loading, setLoading] = useState(false);
  const [markets, setMarkets] = useState(null);
  const [enumeratorData, setEnumeratorData] = useState({
    Name: "",
    Gender: "",
    Phone: "",
    Email: "",
    Password: "",
    Confirm: "", // For password confirmation
    NationalID: "",
    MarketID: "",
    Status: true, // Default status is active
  });
  const [searchMarket, setSearchMarket] = useState("");
  const [error, setError] = useState("");
  const [selectedMarket, setSelectedMarket] = useState("");

  // Fetch markets based on the search query
  useEffect(() => {
    if (searchMarket !== "") {
      fetch(`/api/markets?market=${searchMarket}`)
        .then((res) => {
          if (res.ok) return res.json();
          else {
            res.json().then((data) => {
              throw Error(data.message ?? "Could not fetch!");
            });
          }
        })
        .then((data) => {
          if (data.data.length > 0) {
            setSelectedMarket(data.data[0].Name);
            setMarkets(data.data[0].MarketID);
            setEnumeratorData({
              ...enumeratorData,
              MarketID: data.data[0].MarketID,
            });
          } else {
            setMarkets(null);
            setError("No market found!");
          }
        })
        .catch((err) => {
          setMarkets(null);
          setError(err.message ?? "Oops! something went wrong!");
        });
    } else {
      setError("");
      setMarkets(null);
      setSelectedMarket("");
      setEnumeratorData({
        ...enumeratorData,
        MarketID: "",
      });
    }
  }, [searchMarket]);

  const handleChange = (e) => {
    if (e.target.name === "Market") {
      setSearchMarket(e.target.value);
    }
    setEnumeratorData({
      ...enumeratorData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    setError("");
    setLoading(true);

    // Simple validation
    if (
      !enumeratorData.Name ||
      !enumeratorData.Gender ||
      !enumeratorData.Phone ||
      !enumeratorData.Email ||
      !enumeratorData.Password ||
      !enumeratorData.Confirm ||
      !enumeratorData.MarketID ||
      !enumeratorData.NationalID
    ) {
      setError("Please fill all required fields");
      setLoading(false);
      return;
    }

    if (enumeratorData.Password !== enumeratorData.Confirm) {
      setError("Passwords do not match");
      setLoading(false);
      return;
    }

    fetch(`/api/enumerators`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(enumeratorData),
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((data) => {
            throw new Error(data.message || "An error occurred");
          });
        }
        return res.json();
      })
      .then(() => {
        setLoading(false);
        resetForm();
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message || "Failed to create enumerator");
      });
  };

  const resetForm = () => {
    setEnumeratorData({
      Name: "",
      Gender: "",
      Phone: "",
      Email: "",
      Password: "",
      Confirm: "",
      NationalID: "",
      MarketID: "",
      Status: true,
    });
    setSearchMarket("");
    setSelectedMarket("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Create New Enumerator</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={2}>
          <Alert color={searchMarket === "" ? "warning" : "success"}>
            {searchMarket === ""
              ? "Search market below"
              : selectedMarket !== ""
              ? selectedMarket
              : error !== ""
              ? error
              : "..."}
          </Alert>
          <TextField
            sx={{ mt: 1 }}
            label="Search Market"
            name="Market"
            value={searchMarket}
            onChange={handleChange}
            fullWidth
          />
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                label="Phone"
                name="Phone"
                type="phone"
                value={enumeratorData.Phone}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                label="Email"
                name="Email"
                type="email"
                value={enumeratorData.Email}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
          </Grid2>
          <Grid2 container spacing={2}>
            <Grid2 size={{ md: 4, xs: 12 }}>
              <TextField
                label="Name"
                name="Name"
                value={enumeratorData.Name}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <TextField
                label="National ID"
                name="NationalID"
                value={enumeratorData.NationalID}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
            <Grid2 size={{ md: 4, xs: 12 }}>
              <FormControl fullWidth>
                <InputLabel>Gender</InputLabel>
                <Select
                  name="Gender"
                  value={enumeratorData.Gender}
                  onChange={handleChange}
                  fullWidth
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
              </FormControl>
            </Grid2>
          </Grid2>
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                label="Password"
                name="Password"
                type="password"
                value={enumeratorData.Password}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                label="Confirm Password"
                name="Confirm"
                type="password"
                value={enumeratorData.Confirm}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
          </Grid2>
          {error ? (
            <Alert color="warning">{error}</Alert>
          ) : (
            <Box sx={{ height: "34px" }}></Box>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={resetForm} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
