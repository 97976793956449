import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  Pagination,
  TextField,
  Chip,
  Avatar,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import NewClerkDialog from "./NewClerkDialog";

export default function Clerks() {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/clerks?offset=${offset * 10}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [offset, refresh]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      fetch(`/api/clerks?${column}=${value}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => {
          setData(data);
        })
        .catch(() => setData(null));
    } else {
      setRefresh(!refresh);
    }
  };

  return (
    <Box>
      <Box sx={{ padding: "0" }}>
        <Box
          display="flex"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box sx={{ flexGrow: 1 }}></Box>
          <FormControl>
            <InputLabel size="small">Search by...</InputLabel>
            <Select
              label="Search by..."
              size="small"
              onChange={(e) => {
                setColumn(e.target.value);
              }}
              sx={{ minWidth: "150px" }}
            >
              <MenuItem value="name">Name</MenuItem>
              <MenuItem value="nationalid">ID No.</MenuItem>
              <MenuItem value="phone">Phone</MenuItem>
              <MenuItem value="subcounty">Sub County</MenuItem>
              <MenuItem value="ward">Ward</MenuItem>
              <MenuItem value="market">Market</MenuItem>
              <MenuItem value="category">Category</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            label="Search..."
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Button onClick={() => setOpenDialog(true)} variant="contained">
            <AddIcon /> New Clerk
          </Button>
        </Box>

        {loading ? (
          <div>Loading...</div>
        ) : (
          <TableContainer sx={{ borderRadius: "12px", mt: 0 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">SN</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>ID No</TableCell>
                  <TableCell>Gender</TableCell>
                  <TableCell>SubCounty</TableCell>
                  <TableCell>Ward</TableCell>
                  <TableCell>Market</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data?.length > 0 ? (
                  data.data.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell padding="checkbox">
                        <Chip label={offset * 10 + index + 1} />
                      </TableCell>
                      <TableCell>{item.Name}</TableCell>
                      <TableCell>{item.Phone}</TableCell>
                      <TableCell>{item.NationalID}</TableCell>
                      <TableCell>{item.Gender}</TableCell>
                      <TableCell>{item?.Market?.SubCounty}</TableCell>
                      <TableCell>{item?.Market?.Ward}</TableCell>
                      <TableCell>{item?.Market?.Name}</TableCell>
                      <TableCell>
                        <Chip
                          color={item?.Status ? "success" : "warning"}
                          label={item?.Status ? "Active" : "Disabled"}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>No Clerks Found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Box mt={1}>
          {data && (
            <Pagination
              count={Math.ceil(data.total / 10)}
              page={offset + 1}
              onChange={(e, value) => setOffset(value - 1)}
            />
          )}
        </Box>
      </Box>

      <NewClerkDialog
        open={openDialog}
        onClose={() => {
          setRefresh(!refresh);
          setOpenDialog(false);
        }}
      />
    </Box>
  );
}
