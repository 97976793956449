import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { BarChart } from "@mui/x-charts/BarChart";
import { Ballot } from "@mui/icons-material";

function ProductDashboard() {
  const [data, setData] = useState();
  const [height, setHeight] = useState(300);
  const [priceChart, setPriceChart] = useState(null);
  const [type, setType] = useState("count");

  useEffect(() => {
    const fetchData = async () => {
      try {
        fetch("/api/products/statistics", {
          credentials: "include",
        })
          .then((res) => {
            if (res.ok) return res.json();
            else throw new Error("");
          })
          .then((data) => {
            console.log(data);

            setData(data);
            setHeight(data.chartData.productsByName.series.length * 94);
          })
          .catch((e) => {});
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        fetch(`/api/products/products/stats?type=${type}`, {
          credentials: "include",
        })
          .then((res) => {
            if (res.ok) return res.json();
            else throw new Error("");
          })
          .then((data) => {
            console.log(data);
            setPriceChart(data);
            setHeight(data.series.length * 94);
          })
          .catch((e) => {
            console.log(e);
          });
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [type]);

  return (
    <Box marginTop={1}>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, md: 3, sm: 6 }}>
          <TopItem title="Products" value={data?.totalProducts ?? 0} />
        </Grid>

        <Grid size={{ xs: 12, md: 3, sm: 6 }}>
          <TopItem title="Traders" value={data?.totalTraders ?? 0} />
        </Grid>
        <Grid size={{ xs: 12, md: 3, sm: 6 }}>
          <TopItem title="Markets" value={data?.totalMarkets ?? 0} />
        </Grid>
        <Grid size={{ xs: 12, md: 3, sm: 6 }}>
          <TopItem title="Enumerators" value={data?.totalEnumerators ?? 0} />
        </Grid>
        <Grid size={{ md: 6, xs: 12 }}>
          <Card
            style={{
              borderRadius: "16px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
            }}
          >
            <Typography p={2}>Products by SubCounty</Typography>
            <BarChart
              title="Products by SubCounty"
              xAxis={[
                {
                  scaleType: "band",
                  data: data
                    ? data?.chartData.productsBySubCounty.map(
                        (item) => item.name
                      )
                    : ["No data"],
                },
              ]}
              series={[
                {
                  data: data
                    ? data?.chartData.productsBySubCounty.map(
                        (item) => item.value
                      )
                    : [0],
                },
              ]}
              height={300}
            />
          </Card>
        </Grid>
        <Grid size={{ md: 6, xs: 12 }}>
          <Card
            style={{
              borderRadius: "16px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
            }}
          >
            <Typography p={2}>Products by Ward</Typography>
            <BarChart
              sx={{ marginLeft: 2 }}
              title="Products by Ward"
              xAxis={[
                {
                  scaleType: "band",
                  data: data
                    ? data?.chartData.productsByWard.map((item) => item.name)
                    : ["No data"],
                },
              ]}
              series={[
                {
                  data: data
                    ? data?.chartData.productsByWard.map((item) => item.value)
                    : [0],
                },
              ]}
              height={300}
            />
          </Card>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Card
            style={{
              borderRadius: "16px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
            }}
          >
            <Typography p={2}>Products by Ward</Typography>
            <BarChart
              title="Products by Ward"
              xAxis={[
                {
                  scaleType: "band",
                  data: data
                    ? data?.chartData.productsByMarket.map((item) => item.name)
                    : ["No data"],
                },
              ]}
              series={[
                {
                  data: data
                    ? data?.chartData.productsByMarket.map((item) => item.value)
                    : [0],
                },
              ]}
              height={300}
            />
          </Card>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Card
            style={{
              borderRadius: "16px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
            }}
          >
            <Box p={2} sx={{ display: "flex" }}>
              <Typography flexGrow={1}>Products by Name</Typography>
              <Select
                label="Filter"
                size="small"
                value="count"
                onChange={(e) => {
                  setType(e.target.value);
                }}
                sx={{ minWidth: "150px" }}
              >
                <MenuItem value="count">Total Products</MenuItem>
                <MenuItem value="average">Average Price</MenuItem>
                <MenuItem value="max">Max Price</MenuItem>
                <MenuItem value="min">Min Price</MenuItem>
              </Select>
            </Box>
            <BarChart
              title="Products by Market and Name"
              yAxis={[
                {
                  scaleType: "band", // Markets on y-axis
                  data: priceChart ? priceChart.chartCategories : ["No data"], // Market names
                },
              ]}
              xAxis={[
                {
                  scaleType: "linear", // Values on x-axis
                },
              ]}
              series={[{
                data:
                  priceChart 
                    ? priceChart.series
                    : [],
              }]} // Multiple series for products
              layout="horizontal" // Horizontal layout
              height={height}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProductDashboard;

const TopItem = ({ title, value }) => {
  return (
    <Card
      style={{
        borderRadius: "16px",
        boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
      }}
    >
      <CardContent sx={{ position: "relative" }}>
        <Stack spacing={3}>
          <Stack
            direction="row"
            sx={{ justifyContent: "space-between" }}
            spacing={3}
          >
            <Stack spacing={1}>
              <Typography color="text.secondary" variant="overline">
                {title}
              </Typography>
              <Typography variant="h4">{value}</Typography>
            </Stack>
            <Avatar
              sx={{
                backgroundColor: "#fa541c",
                height: "32px",
                width: "32px",
                position: "absolute",
                bottom: 10,
                right: 10,
              }}
            >
              <Ballot fontSize="16px" />
            </Avatar>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
