import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Box,
  Stack,
  Divider,
  Alert,
  Grid2,
} from "@mui/material";

export default function NewProductDialog({ open, onClose }) {
  const [loading, setLoading] = useState(false);
  const [markets, setMarkets] = useState(null);
  const [productData, setProductData] = useState({
    Name: "",
    Description: "",
    Quantity: 0,
    Price: 0.0,
    Trader: "",
    Phone: "",
    MarketID: "",
    EnumeratorID: "",
  });
  const [searchEnumerator, setSearchMarket] = useState("");
  const [error, setError] = useState("");
  const [selectedMarket, setSelectedMarket] = useState("");

  // Fetch markets based on the search query
  useEffect(() => {
    if (searchEnumerator !== "") {
      fetch(`/api/enumerators?name=${searchEnumerator}`)
        .then((res) => {
          if (res.ok) return res.json();
          else {
            res.json().then((data) => {
              throw Error(data.message ?? "Could not fetch!");
            });
          }
        })
        .then((data) => {
          if (data.data.length > 0) {
            setSelectedMarket(
              `${data.data[0].Name} - ${data.data[0].Market.Name}`
            );
            setMarkets(data.data[0].Market.MarketID);
            setProductData({
              ...productData,
              MarketID: data.data[0].Market.MarketID,
              EnumeratorID: data.data[0].UserID,
            });
          } else {
            setMarkets(null);
            setError("No market found!");
          }
        })
        .catch((err) => {
          setMarkets(null);
          setError(err.message ?? "Oops! something went wrong!");
        });
    } else {
      setError("");
      setMarkets(null);
      setSelectedMarket("");
      setProductData({
        ...productData,
        MarketID: "",
        EnumeratorID: "",
      });
    }
  }, [searchEnumerator]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "Market") {
      setSearchMarket(value);
    } else {
      setProductData({
        ...productData,
        [name]: value,
      });
    }
  };

  const handleSubmit = () => {
    setError("");
    setLoading(true);

    // Simple validation
    if (
      !productData.Name ||
      !productData.Description ||
      !productData.Quantity ||
      !productData.Price ||
      !productData.Trader ||
      !productData.Phone ||
      !productData.EnumeratorID ||
      !productData.MarketID
    ) {
      setError("Please fill all required fields");
      setLoading(false);
      return;
    }

    fetch(`/api/products`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json", // Ensure Content-Type is set to JSON
      },
      body: JSON.stringify(productData),
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((data) => {
            throw new Error(data.message || "An error occurred");
          });
        }
        return res.json();
      })
      .then(() => {
        setLoading(false);
        setTimeout(() => {
          setProductData({
            Name: "",
            Description: "",
            Quantity: 0,
            Price: 0.0,
            Trader: "",
            Phone: "",
            MarketID: "",
          });
          setError("");
          setSelectedMarket("");
          setSearchMarket("");
          onClose();
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message || "Failed to create product");
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Create New Product</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={2}>
          <Alert color={searchEnumerator === "" ? "warning" : "success"}>
            {searchEnumerator === ""
              ? "Search enumerator below"
              : selectedMarket !== ""
              ? selectedMarket
              : "..."}
          </Alert>

          <TextField
            sx={{ mt: 1 }}
            label="Search Enumerator"
            name="Market"
            value={searchEnumerator}
            onChange={handleChange}
            fullWidth
          />

          <Grid2 container spacing={2}>
            <Grid2 size={{ md: 7, xs: 12 }}>
              <TextField
                name="Name"
                label="Product Name"
                value={productData.Name}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
            <Grid2 size={{ md: 2.5, xs: 12 }}>
              <TextField
                name="Quantity"
                label="Quantity"
                type="number"
                value={productData.Quantity}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
            <Grid2 size={{ md: 2.5, xs: 12 }}>
              <TextField
                name="Price"
                label="Price"
                type="number"
                value={productData.Price}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
          </Grid2>
          <TextField
            name="Description"
            label="Product Description"
            value={productData.Description}
            onChange={handleChange}
            fullWidth
          />

          <Grid2 container spacing={2}>
            <Grid2 size={{ md: 6, xs: 12 }}>
              <TextField
                name="Trader"
                label="Trader Name"
                value={productData.Trader}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
            <Grid2 size={{ md: 6, xs: 12 }}>
              <TextField
                name="Phone"
                label="Trader Phone"
                value={productData.Phone}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
          </Grid2>
          {error ? (
            <Alert color="warning">{error}</Alert>
          ) : (
            <Box sx={{ height: "14px" }}></Box>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
